import React from 'react';
import { Button } from './button';

const AppleStoreButton = () => {
  return (
    <a
      href="https://apps.apple.com/br/app/fusion-by-c-one/id1671525916"
      target="_blank"
      className="bg-black h-11 w-[148.5px] rounded-[5px] border border-[#A6A6A6] flex items-center gap-1 justify-center"
      aria-describedby="apple-store-link"
    >
      <svg
        width="20"
        height="25"
        viewBox="0 0 20 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4958 13.3312C16.5196 11.4821 17.5127 9.73241 19.088 8.7639C18.0942 7.34455 16.4296 6.44464 14.6977 6.39045C12.8506 6.19657 11.0598 7.49577 10.1185 7.49577C9.15905 7.49577 7.7098 6.4097 6.14919 6.44181C4.11502 6.50753 2.21865 7.66405 1.22898 9.44247C-0.898418 13.1257 0.68843 18.5389 2.7263 21.5162C3.74589 22.974 4.9375 24.6025 6.4967 24.5448C8.02246 24.4815 8.59231 23.5719 10.434 23.5719C12.2587 23.5719 12.7933 24.5448 14.3841 24.5081C16.0214 24.4815 17.0529 23.0437 18.0367 21.572C18.7693 20.5332 19.333 19.3851 19.707 18.1703C17.7829 17.3565 16.498 15.4202 16.4958 13.3312Z"
          fill="white"
        />
        <path
          d="M13.491 4.43246C14.3836 3.36083 14.8234 1.98343 14.7169 0.592773C13.3531 0.736016 12.0933 1.38783 11.1886 2.41834C10.3039 3.42521 9.84347 4.77837 9.93052 6.11587C11.2948 6.12992 12.636 5.49577 13.491 4.43246Z"
          fill="white"
        />
      </svg>

      <div className="flex flex-col">
        <svg
          width="77"
          height="8"
          viewBox="0 0 77 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.466797 0.581958V7.16987H2.84539C4.80852 7.16987 5.94531 5.96003 5.94531 3.85537C5.94531 1.78267 4.79939 0.581958 2.84539 0.581958H0.466797ZM1.48945 1.51331H2.73125C4.09631 1.51331 4.9044 2.38074 4.9044 3.86907C4.9044 5.38022 4.11001 6.23852 2.73125 6.23852H1.48945V1.51331Z"
            fill="white"
          />
          <path
            d="M9.42691 7.26575C10.8787 7.26575 11.769 6.29331 11.769 4.68171C11.769 3.07925 10.8742 2.10225 9.42691 2.10225C7.9751 2.10225 7.08028 3.07925 7.08028 4.68171C7.08028 6.29331 7.97054 7.26575 9.42691 7.26575ZM9.42691 6.39375C8.57317 6.39375 8.0938 5.76829 8.0938 4.68171C8.0938 3.60427 8.57317 2.97424 9.42691 2.97424C10.2761 2.97424 10.76 3.60427 10.76 4.68171C10.76 5.76372 10.2761 6.39375 9.42691 6.39375Z"
            fill="white"
          />
          <path
            d="M19.3503 2.19812H18.3688L17.4831 5.99199H17.4055L16.3828 2.19812H15.4423L14.4197 5.99199H14.3466L13.4564 2.19812H12.4611L13.8307 7.16987H14.8397L15.8623 3.5084H15.94L16.9672 7.16987H17.9853L19.3503 2.19812Z"
            fill="white"
          />
          <path
            d="M20.4807 7.16987H21.4623V4.26169C21.4623 3.48557 21.9234 3.00164 22.6493 3.00164C23.3752 3.00164 23.7222 3.39883 23.7222 4.19778V7.16987H24.7038V3.95124C24.7038 2.7688 24.092 2.10225 22.9826 2.10225C22.2339 2.10225 21.7408 2.43552 21.4988 2.98794H21.4258V2.19812H20.4807V7.16987Z"
            fill="white"
          />
          <path d="M26.2496 7.16987H27.2312V0.257812H26.2496V7.16987Z" fill="white" />
          <path
            d="M30.9228 7.26575C32.3746 7.26575 33.2648 6.29331 33.2648 4.68171C33.2648 3.07925 32.37 2.10225 30.9228 2.10225C29.471 2.10225 28.5762 3.07925 28.5762 4.68171C28.5762 6.29331 29.4664 7.26575 30.9228 7.26575ZM30.9228 6.39375C30.069 6.39375 29.5897 5.76829 29.5897 4.68171C29.5897 3.60427 30.069 2.97424 30.9228 2.97424C31.772 2.97424 32.2559 3.60427 32.2559 4.68171C32.2559 5.76372 31.772 6.39375 30.9228 6.39375Z"
            fill="white"
          />
          <path
            d="M36.1895 6.42571C35.6553 6.42571 35.2672 6.16548 35.2672 5.71807C35.2672 5.27978 35.5777 5.04695 36.2625 5.00129L37.4769 4.92368V5.33914C37.4769 5.95547 36.9291 6.42571 36.1895 6.42571ZM35.9384 7.25205C36.5912 7.25205 37.1345 6.96899 37.4313 6.47136H37.5089V7.16987H38.4539V3.77319C38.4539 2.72314 37.7508 2.10225 36.5045 2.10225C35.3768 2.10225 34.5733 2.6501 34.4729 3.50383H35.4225C35.532 3.15229 35.911 2.95142 36.4588 2.95142C37.1299 2.95142 37.4769 3.24817 37.4769 3.77319V4.20234L36.1301 4.27996C34.9477 4.353 34.2811 4.8689 34.2811 5.76372C34.2811 6.67224 34.9796 7.25205 35.9384 7.25205Z"
            fill="white"
          />
          <path
            d="M41.7849 7.25205C42.4697 7.25205 43.0495 6.9279 43.3462 6.38462H43.4238V7.16987H44.3643V0.257812H43.3828V2.98794H43.3097C43.0403 2.44009 42.4651 2.11594 41.7849 2.11594C40.5294 2.11594 39.7213 3.11121 39.7213 4.68171C39.7213 6.25679 40.5202 7.25205 41.7849 7.25205ZM42.0633 2.99707C42.8851 2.99707 43.401 3.64993 43.401 4.68628C43.401 5.7272 42.8897 6.37092 42.0633 6.37092C41.2324 6.37092 40.7348 5.73633 40.7348 4.68171C40.7348 3.63623 41.237 2.99707 42.0633 2.99707Z"
            fill="white"
          />
          <path
            d="M50.7294 7.26575C52.1813 7.26575 53.0715 6.29331 53.0715 4.68171C53.0715 3.07925 52.1767 2.10225 50.7294 2.10225C49.2776 2.10225 48.3828 3.07925 48.3828 4.68171C48.3828 6.29331 49.2731 7.26575 50.7294 7.26575ZM50.7294 6.39375C49.8757 6.39375 49.3963 5.76829 49.3963 4.68171C49.3963 3.60427 49.8757 2.97424 50.7294 2.97424C51.5786 2.97424 52.0625 3.60427 52.0625 4.68171C52.0625 5.76372 51.5786 6.39375 50.7294 6.39375Z"
            fill="white"
          />
          <path
            d="M54.3708 7.16987H55.3524V4.26169C55.3524 3.48557 55.8135 3.00164 56.5394 3.00164C57.2653 3.00164 57.6123 3.39883 57.6123 4.19778V7.16987H58.5939V3.95124C58.5939 2.7688 57.9821 2.10225 56.8727 2.10225C56.124 2.10225 55.6309 2.43552 55.3889 2.98794H55.3159V2.19812H54.3708V7.16987Z"
            fill="white"
          />
          <path
            d="M63.1145 0.960889V2.22095H62.3247V3.04729H63.1145V5.85503C63.1145 6.81377 63.5483 7.19727 64.6394 7.19727C64.8311 7.19727 65.0138 7.17444 65.1736 7.14705V6.32983C65.0366 6.34353 64.9498 6.35266 64.7992 6.35266C64.3107 6.35266 64.0961 6.12439 64.0961 5.60393V3.04729H65.1736V2.22095H64.0961V0.960889H63.1145Z"
            fill="white"
          />
          <path
            d="M66.4911 7.16987H67.4727V4.26626C67.4727 3.51296 67.9201 3.0062 68.7236 3.0062C69.4176 3.0062 69.7874 3.40796 69.7874 4.20234V7.16987H70.7689V3.96038C70.7689 2.77793 70.1161 2.10681 69.0569 2.10681C68.3082 2.10681 67.7831 2.44009 67.5412 2.99707H67.4636V0.257812H66.4911V7.16987Z"
            fill="white"
          />
          <path
            d="M74.3099 2.93772C75.0358 2.93772 75.5106 3.44448 75.5334 4.22517H73.0224C73.0772 3.44905 73.584 2.93772 74.3099 2.93772ZM75.5289 5.82764C75.3462 6.2157 74.9399 6.43027 74.3464 6.43027C73.5612 6.43027 73.0544 5.87786 73.0224 5.00586V4.95107H76.5332V4.60867C76.5332 3.04729 75.6978 2.10225 74.3145 2.10225C72.9129 2.10225 72.0226 3.11121 72.0226 4.69998C72.0226 6.28874 72.8946 7.26575 74.319 7.26575C75.4558 7.26575 76.2502 6.7179 76.4739 5.82764H75.5289Z"
            fill="white"
          />
        </svg>

        <svg
          width="83"
          height="18"
          viewBox="0 0 83 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M51.9293 2.12139V4.6415H50.3497V6.29419H51.9293V11.9097C51.9293 13.8271 52.7968 14.5941 54.979 14.5941C55.3625 14.5941 55.7278 14.5485 56.0473 14.4937V12.8593C55.7734 12.8867 55.5999 12.9049 55.2986 12.9049C54.3216 12.9049 53.8925 12.4484 53.8925 11.4075V6.29419H56.0473V4.6415H53.8925V2.12139H51.9293Z"
            fill="white"
          />
          <path
            d="M61.6066 14.7311C64.5102 14.7311 66.2907 12.7862 66.2907 9.56304C66.2907 6.3581 64.5011 4.4041 61.6066 4.4041C58.703 4.4041 56.9133 6.3581 56.9133 9.56304C56.9133 12.7862 58.6938 14.7311 61.6066 14.7311ZM61.6066 12.9871C59.8991 12.9871 58.9404 11.7362 58.9404 9.56304C58.9404 7.40815 59.8991 6.14809 61.6066 6.14809C63.3049 6.14809 64.2728 7.40815 64.2728 9.56304C64.2728 11.727 63.3049 12.9871 61.6066 12.9871Z"
            fill="white"
          />
          <path
            d="M67.8141 14.5394H69.7772V8.66821C69.7772 7.27119 70.8273 6.33071 72.3156 6.33071C72.6626 6.33071 73.247 6.39463 73.4113 6.44941V4.51367C73.2013 4.45889 72.8269 4.43149 72.5348 4.43149C71.2382 4.43149 70.1333 5.1437 69.8503 6.1207H69.7042V4.59585H67.8141V14.5394Z"
            fill="white"
          />
          <path
            d="M78.2857 6.07505C79.7375 6.07505 80.6871 7.08857 80.7328 8.64995H75.7108C75.8204 7.0977 76.8339 6.07505 78.2857 6.07505ZM80.7237 11.8549C80.3584 12.631 79.5458 13.0602 78.3588 13.0602C76.7883 13.0602 75.7747 11.9553 75.7108 10.2113V10.1018H82.7325V9.41694C82.7325 6.29419 81.0615 4.4041 78.2949 4.4041C75.4917 4.4041 73.7112 6.42202 73.7112 9.59956C73.7112 12.7771 75.4552 14.7311 78.304 14.7311C80.5776 14.7311 82.1664 13.6354 82.6138 11.8549H80.7237Z"
            fill="white"
          />
          <path
            d="M39.0554 10.8674C39.2068 13.3091 41.2415 14.8706 44.27 14.8706C47.5066 14.8706 49.5319 13.2333 49.5319 10.6213C49.5319 8.56765 48.3773 7.43199 45.576 6.77898L44.0712 6.40989C42.292 5.99348 41.5728 5.43511 41.5728 4.46034C41.5728 3.23003 42.6895 2.4256 44.3646 2.4256C45.9545 2.4256 47.0524 3.2111 47.2511 4.4698H49.3142C49.1912 2.17008 47.1659 0.551758 44.393 0.551758C41.4119 0.551758 39.4245 2.17008 39.4245 4.60229C39.4245 6.60863 40.5507 7.80108 43.0207 8.37838L44.781 8.80425C46.5886 9.23012 47.3836 9.85474 47.3836 10.8958C47.3836 12.1071 46.1344 12.9873 44.4309 12.9873C42.6043 12.9873 41.3362 12.1639 41.1564 10.8674H39.0554Z"
            fill="white"
          />
          <path
            d="M18.7193 4.43149C17.368 4.43149 16.1992 5.10718 15.5966 6.2394H15.4505V4.59585H13.5604V17.8447H15.5235V13.0328H15.6787C16.1992 14.0828 17.3223 14.7037 18.7376 14.7037C21.2486 14.7037 22.8465 12.7223 22.8465 9.56304C22.8465 6.40376 21.2486 4.43149 18.7193 4.43149ZM18.1623 12.9415C16.5188 12.9415 15.487 11.6449 15.487 9.57217C15.487 7.49033 16.5188 6.19375 18.1715 6.19375C19.8333 6.19375 20.8285 7.46294 20.8285 9.56304C20.8285 11.6723 19.8333 12.9415 18.1623 12.9415Z"
            fill="white"
          />
          <path
            d="M29.7158 4.43149C28.3644 4.43149 27.1957 5.10718 26.593 6.2394H26.4469V4.59585H24.5569V17.8447H26.52V13.0328H26.6752C27.1957 14.0828 28.3188 14.7037 29.7341 14.7037C32.245 14.7037 33.8429 12.7223 33.8429 9.56304C33.8429 6.40376 32.245 4.43149 29.7158 4.43149ZM29.1588 12.9415C27.5153 12.9415 26.4835 11.6449 26.4835 9.57217C26.4835 7.49033 27.5153 6.19375 29.1679 6.19375C30.8298 6.19375 31.825 7.46294 31.825 9.56304C31.825 11.6723 30.8298 12.9415 29.1588 12.9415Z"
            fill="white"
          />
          <path
            d="M10.0382 14.5394H12.2906L7.3599 0.882993H5.07911L0.148438 14.5394H2.32513L3.58382 10.9147H8.78895L10.0382 14.5394ZM6.11067 3.36253H6.27156L8.24951 9.13548H4.12326L6.11067 3.36253Z"
            fill="white"
          />
        </svg>
      </div>
    </a>
  );
};

export default AppleStoreButton;
