'use client';
import React from 'react';
import GooglePlayButton from './atom/google-play-button';
import AppleStoreButton from './atom/apple-store-button';
import { Button } from './atom/button';

const GotoMobileAppModal = ({
  title = 'My Wallet',
  feature = 'wallet',
  onGoBack,
  description,
}: {
  onGoBack?: () => void;
  title?: string;
  feature?: string;
  description?: string;
}) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 backdrop-blur-xl flex items-center justify-center bg-purple/10 rounded-lg">
      <div className="bg-white shadow-sm rounded-lg w-full max-w-[518px] py-10">
        <div className="w-full max-w-[358px] flex flex-col gap-6 items-center  justify-center mx-auto pt-5 pb-20 backdrop-blur-xl">
          <div className="flex flex-col items-center justify-center gap-2">
            <h1 className="text-2xl text-purple-brand3 font-medium text-center">{title}</h1>
            <p className="text-base text-gray-brand6 text-center">
              {description || `To use this feature, navigate to ${feature} on your mobile app`}
            </p>
          </div>

          <div className="mt-10 flex items-center justify-center gap-4 w-full">
            <GooglePlayButton />
            <AppleStoreButton />
          </div>
        </div>
      </div>

      {onGoBack && (
        <Button variant="link" size="sm">
          Go back
        </Button>
      )}
    </div>
  );
};

export default GotoMobileAppModal;
